/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import Questions from 'components/Questions.js'
import ButtonDefaultOutline from 'components/elements/ButtonDefaultOutline'

const CtaTitle = styled.h4``
export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageFaq {
        headerfaq {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        questions {
          titleConcept
          questionConcept {
            ... on WpVraag {
              id
              title
              content
            }
          }
          titleApplicability
          questionsApplicability {
            ... on WpVraag {
              id
              title
              content
            }
          }
          titlePrejudices
          questionsPrejudices {
            ... on WpVraag {
              id
              title
              content
            }
          }
          titleAbout
          questionsAbout {
            ... on WpVraag {
              id
              title
              content
            }
          }
        }

        cta: callToAction {
          title
          buttonText
          buttonUrl
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, pageFaq, seo },
  },
}) => (
  <Layout subFooter>
    {seo && <SEO seo={seo} />}
    <Hero title={title} src={pageFaq.headerfaq.image} />
    <section>
      <div className="container">
        <div className="row mb-5 pb-4">
          <div className="col-lg-10 mx-auto">
            <div className="d-flex flex-column  color-background-secondary mt-n5 p-lg-5 p-4  shadow">
              <h4 className="font-family-secondary font-size-xl text-uppercase mb-5">
                {pageFaq.questions.titleConcept}
              </h4>
              <Questions questIds={pageFaq.questions.questionConcept} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="color-background-greyAlt">
      <div className="container py-4">
        <div className="row py-5" />
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row mb-5 pb-4">
          <div className="col-lg-10 mx-auto mt-n4">
            <div className="d-flex flex-column  color-background-secondary mt-n5 p-lg-5 p-4 shadow">
              <h4 className="font-family-secondary font-size-xl text-uppercase mb-5">
                {pageFaq.questions.titleApplicability}
              </h4>
              <Questions questIds={pageFaq.questions.questionsApplicability} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="color-background-greyAlt">
      <div className="container py-4">
        <div className="row py-5" />
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row mb-5 pb-lg-5">
          <div className="col-lg-10 mx-auto mt-n4">
            <div className="d-flex flex-column  color-background-secondary mt-n5 p-lg-5 p-4 shadow">
              <h4 className="font-family-secondary font-size-xl text-uppercase mb-5">
                {pageFaq.questions.titlePrejudices}
              </h4>
              <Questions questIds={pageFaq.questions.questionsPrejudices} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="color-background-greyAlt">
      <div className="container py-4">
        <div className="row py-5" />
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row mb-5 pb-lg-5">
          <div className="col-lg-10 mx-auto mt-n4">
            <div className="d-flex flex-column  color-background-secondary mt-n5 p-lg-5 p-4 shadow">
              <h4 className="font-family-secondary font-size-xl text-uppercase mb-5">
                {pageFaq.questions.titleAbout}
              </h4>
              <Questions questIds={pageFaq.questions.questionsAbout} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-5">
        <div className="row py-5">
          <div className="col-lg-8">
            <CtaTitle className="mb-4 mb-lg-0">{pageFaq.cta.title}</CtaTitle>
          </div>
          <div className="col-lg-4">
            <ButtonDefaultOutline to={pageFaq.cta.buttonUrl}>
              {pageFaq.cta.buttonText}
            </ButtonDefaultOutline>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PageTemplate
